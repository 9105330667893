<template>
    <ASelect
        mode="multiple"
        v-model:value="valueState"
        placeholder="Pilih Operator"
        allow-clear
        show-search
        :not-found-content="state.loading ? undefined : 'Tidak ditemukan'"
        option-filter-prop="label"
        option-label-prop="label"
        @search="onSearch"
        @blur="() => (state.params.q = null)"
        @select="() => (state.params.q = null)"
        v-bind="$attrs"
        :loading="state.loading"
        :show-arrow="true"
        style="width:300px">
        <ASelectOption v-for="d in state.data" :key="d.value" :label="d.label">
            <span v-html="highlight(d.label)"></span>
        </ASelectOption>
        <template v-if="state.loading" #notFoundContent>
            <ASpin size="small" />
        </template>
    </ASelect>
</template>

<script>
import { reactive } from 'vue'
import { useVModel } from '@/components/useVModel.js'

export default {
    props: {
        value: {
            type: [Array, Number, Object, String],
            default: null,
        },
    },
    emits: ['update:value'],
    setup(props, { emit }) {
        const state = reactive({
            loading: false,
            data: [
                {
                    "value": "=",
                    "label": "=",
                },
                {
                    "value": "<",
                    "label": "<",
                },
                {
                    "value": ">",
                    "label": ">",
                },
                {
                    "value": "<=",
                    "label": "<=",
                },
                {
                    "value": ">=",
                    "label": ">=",
                },
                {
                    "value": "!=",
                    "label": "!=",
                },
                {
                    "value": "~",
                    "label": "~",
                },
                {
                    "value": "!~",
                    "label": "!~",
                },
                {
                    "value": "LIKE",
                    "label": "LIKE",
                },
                {
                    "value": "LIKE %%",
                    "label": "LIKE %%",
                },
                {
                    "value": "ILIKE",
                    "label": "ILIKE",
                },
                {
                    "value": "ILIKE %%",
                    "label": "ILIKE %%",
                },
                {
                    "value": "IN",
                    "label": "IN",
                },
                {
                    "value": "IS NULL",
                    "label": "IS NULL",
                },
                {
                    "value": "NOT LIKE",
                    "label": "NOT LIKE",
                },
                {
                    "value": "NOT IN",
                    "label": "NOT IN",
                },
                {
                    "value": "IS NOT NULL",
                    "label": "IS NOT NULL",
                },
            ],
            params: {
                q: '',
            },
        })

        const onSearch = value => {
            state.params.q = value
        }

        const highlight = value => {
            return value.replace(new RegExp(state.params.q, 'gi'), match => {
                return `<span style="background-color: yellow;">${match}</span>`
            })
        }

        return {
            state,
            valueState: useVModel(props, 'value'),
            highlight,
            onSearch,
        }
    },
}
</script>