// Helper untuk Query Builder PostgreSQL
const QueryHelper = {
    initialize() {
        return {
        query: '',
        type: '',
        table: '',
        columns: ['*'],
        values: [],
        conditions: [],
        joins: [],
        orderBy: '',
        limitCount: null,
        offsetCount: null,
        }
    },

    select(context, columns = ['*']) {
        context.type = 'SELECT'
        context.columns = columns
        return context
    },

    insert(context, table, data) {
        context.type = 'INSERT'
        context.table = table
        context.columns = Object.keys(data)
        context.values = Object.values(data)
        return context
    },

    update(context, table, data) {
        context.type = 'UPDATE'
        context.table = table
        context.values = data
        return context
    },

    delete(context, table) {
        context.type = 'DELETE'
        context.table = table
        return context
    },

    from(context, table) {
        context.table = table
        return context
    },

    where(context, condition) {
        context.conditions.push(condition)
        return context
    },

    join(context, table, onCondition, type = 'INNER') {
        context.joins.push(`${type} JOIN ${table} ON ${onCondition}`)
        return context
    },

    orderBy(context, column, direction = 'ASC') {
        context.orderBy = `ORDER BY ${column} ${direction}`
        return context
    },

    limit(context, count) {
        context.limitCount = count
        return context
    },

    offset(context, count) {
        context.offsetCount = count
        return context
    },

    build(context) {
        if (context.type === 'SELECT') {
            context.query = `SELECT ${context.columns.join(', ')} FROM ${context.table}`
        } else if (context.type === 'INSERT') {
            const placeholders = context.values.map((_, i) => `$${i + 1}`).join(', ')
            context.query = `INSERT INTO ${context.table} (${context.columns.join(', ')}) VALUES (${placeholders})`
        } else if (context.type === 'UPDATE') {
            const setClause = Object.keys(context.values)
                .map((key, i) => `${key} = $${i + 1}`)
                .join(', ')
            context.query = `UPDATE ${context.table} SET ${setClause}`
        } else if (context.type === 'DELETE') {
            context.query = `DELETE FROM ${context.table}`
        }

        if (context.joins.length) {
            context.query += ' ' + context.joins.join(' ')
        }

        if (context.conditions.length) {
            context.query += ' WHERE ' + context.conditions.join(' AND ')
        }

        if (context.orderBy) {
            context.query += ' ' + context.orderBy
        }

        if (context.limitCount !== null) {
            context.query += ` LIMIT ${context.limitCount}`
        }

        if (context.offsetCount !== null) {
            context.query += ` OFFSET ${context.offsetCount}`
        }

        return context.query
    },

    getValues(context) {
        const values = Array.isArray(context.values) ? Object.values(context.values) : context.values
        return values
    },
}

export default QueryHelper